import * as React from "react"
import { FC, ReactNode } from 'react';
import { ConnectionProvider, useConnection, useWallet, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import * as web3 from '@solana/web3.js'
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { PhantomWalletAdapter, SolflareWalletAdapter, UnsafeBurnerWalletAdapter } from "@solana/wallet-adapter-wallets";
require('@solana/wallet-adapter-react-ui/styles.css');

const WalletContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const network = WalletAdapterNetwork.Mainnet;
  	const endpoint = web3.clusterApiUrl(network);
	const wallets = [
				/**
				 * Wallets that implement either of these standards will be available automatically.
				 *
				 *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
				 *     (https://github.com/solana-mobile/mobile-wallet-adapter)
				 *   - Solana Wallet Standard
				 *     (https://github.com/solana-labs/wallet-standard)
				 *
				 * If you wish to support a wallet that supports neither of those standards,
				 * instantiate its legacy wallet adapter here. Common legacy adapters can be found
				 * in the npm package `@solana/wallet-adapter-wallets`.
				 */
				new PhantomWalletAdapter(),
				new SolflareWalletAdapter(),
				//new UnsafeBurnerWalletAdapter(),
		];

	return (
		<ConnectionProvider endpoint={endpoint}>
			<WalletProvider wallets={wallets} autoConnect>
					<WalletModalProvider>
							{children}
					</WalletModalProvider>
			</WalletProvider>
		</ConnectionProvider>
	)
}

export default WalletContextProvider